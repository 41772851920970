import React from 'react'
import PropTypes from 'prop-types'
import { getSize } from '../../Helpers'
import style from '../styles/SectionImage.module.css'


function SectionImage({image}) {
  const url = getSize(image, 'large')

  return <div><img src={url} alt={''} className={style.image} /></div>
}

SectionImage.propTypes = {
  image: PropTypes.object.isRequired
}

export default SectionImage
