import React from 'react'
import PropTypes from 'prop-types'
import SectionTitle from './SectionTitle'
import SectionImage from './SectionImage'
import HTMLText from '../../text/components/HTMLText'
import style from '../styles/SectionContent.module.css'


function SectionContent({item, button}) {
  const alignment = item.TextAlignment ? item.TextAlignment : 'left'
  const color = item.Color ? item.Color : 'light'

  const sectionTitle = item.Title ? <SectionTitle customAlignment={alignment} title={item.Title} customColor={color} /> : ''
  const publishedDate = item.date ? <div className={style.date}>Published At: {new Date(item.date).toISOString().split('T')[0]}</div> : ''

  return (
    <div className={style.content}>
      {sectionTitle}
      {publishedDate}
      <HTMLText text={item.Content} customAlignment={alignment} customColor={color} />
      {button}
    </div>
  )
}

SectionContent.propTypes = {
  item: PropTypes.object.isRequired
}

export default SectionContent
