import React from 'react'
import style from '../styles/ContactMap.module.css'


function ContactMap({location}) {
  return (
    <div className={style.container}>
      <div className={style.map}>
        <iframe title={'Google Map'} src={`https://maps.google.com/maps?q=${encodeURI(location)}&t=&z=11&ie=UTF8&iwloc=&output=embed`} width="600" height="450" allowFullScreen={true}></iframe>
      </div>
    </div>
  )
}

export default ContactMap
