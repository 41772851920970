import React, { useState, useEffect } from 'react'
import NavigationLinks from './NavigationLinks'
import NavigationContacts from './NavigationContacts'
import NavigationSocials from './NavigationSocials'
import NavigationLogo from './NavigationLogo'
import style from '../styles/Navigation.module.css'


function Navigation({navigation, settings}) {
  const [isActive, setIsActive] = useState(false)
  const onSandwichClick = () => setIsActive(!isActive)

  useEffect(() => {
    // Set inline style on the body when isNavActive changes
    document.body.style.overflow = isActive ? 'hidden' : 'auto'

    // Cleanup: Reset the style when the component unmounts
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isActive])

  return (
    <React.Fragment>
      <div className={`${style.sandwich} ${isActive ? style.sandwichActive : ''}`} onClick={onSandwichClick}>
        {isActive ? 'Close' : 'Menu'}
      </div>
      <div className={`${style.navigation} ${isActive ? style.active : ''}`}>
        <NavigationLogo />
        <NavigationLinks navigation={navigation.Navigation} setIsActive={setIsActive} />
        <NavigationContacts settings={settings} />
        <NavigationSocials settings={settings} />
      </div>
    </React.Fragment>
  )
}

export default Navigation
