import React from 'react'
import ContactForm from './ContactForm'
import ContactMap from './ContactMap'
import style from '../styles/Contact.module.css'


function Contact({item}) {
  const background = item.Background
  const location = item.GoogleMapLocation

  return (
    <div className={`${style.container} ${style[background]}`}>
      <ContactForm />
      <ContactMap location={location} />
    </div>
  )
}

export default Contact
