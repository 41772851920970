import React from 'react'
import Section from './section/components/Section'
import Header from './header/components/Header'


function NotFoundScreen() {
  const title = `Page Not Found`
  const item = {
    Content: `The page you were looking for doesn't exist. You may have mistyped the address or the page may have moved.`
  }

  return (
    <React.Fragment>
      <Header title={title} />
      <Section item={item} />
    </React.Fragment>

  )
}

export default NotFoundScreen
