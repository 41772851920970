import React from 'react'
import PropTypes from 'prop-types'
import FooterTitle from './FooterTitle'
import style from '../styles/FooterItem.module.css'


function FooterItem({title, children}) {
  return  (
    <div className={style.container}>
      <FooterTitle title={title} />
      {children}
    </div>
  )
}

FooterItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired
}

export default FooterItem
