import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import style from '../styles/HeroButton.module.css'


function HeroButton({title, url, color}) {
  return <Link to={url} className={`${style.button} ${style[color]}`}>{title}</Link>
}

HeroButton.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
}

export default HeroButton
