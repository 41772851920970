import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/TestimonialText.module.css'


function TestimonialText({text}) {
  return <div className={style.container}>{text}</div>
}

TestimonialText.propTypes = {
  text: PropTypes.string.isRequired
}

export default TestimonialText