import React from 'react'
import PropTypes from 'prop-types'
import FooterItem from './FooterItem'
import FooterLink from './FooterLink'
import FooterText from './FooterText'


function FooterContact({settings}) {
  const tel = settings.Telephone
  const fax = settings.Fax
  const email = settings.Email

  const contact = [
    tel ? <FooterLink key={0} label={`Telephone`} url={`tel:${tel}`} title={tel} /> : '',
    fax ? <FooterText key={1} text={`Fax: ${fax}`} /> : '',
    email ? <FooterLink key={2} label={`E-mail`} url={`mailto:${email}`} title={email} /> : ''
  ]

  return (
    <FooterItem title={`Contact`}>
      {contact}
    </FooterItem>
  )
}

FooterContact.propTypes = {
  settings: PropTypes.object.isRequired
}

export default FooterContact
