import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/BoxIcon.module.css'


function BoxIcon({icon}) {
  return <div className={style.container} style={{backgroundImage: `url('${icon}')`}}></div>
}

BoxIcon.propTypes = {
  icon: PropTypes.string.isRequired
}

export default BoxIcon
