import React from 'react'
import { Link } from 'react-router-dom'
import style from '../styles/NavigationLogo.module.css'


function NavigationLogo() {
  return <Link to={`/`} className={style.logo}></Link>
}

export default NavigationLogo
