import React, { useState, useEffect } from 'react'
import style from './styles/App.module.css'

import logo from './navigation/images/logo.png'


function LoadingScreen() {
  const [dots, setDots] = useState('.')

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (dots.length < 3) {
        setDots(`${dots}.`)
      } else {
        setDots('.')
      }

    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  })

  return (
    <div className={style.container}>
      <div className={style.loading}>
        <img src={logo} alt={'Long Beach'} />
        <span>Loading {dots}</span>
      </div>
    </div>
  )
}

export default LoadingScreen
