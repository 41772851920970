import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/FooterTitle.module.css'


function FooterTitle({title}) {
  return <div className={style.title}>{title}</div>
}

FooterTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default FooterTitle
