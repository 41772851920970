import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/Player.module.css'


function Player({ item }) {
  return (
    <div className={style.container}>
      <iframe
        className={style.iframe}
        src={`https://www.youtube.com/embed/${item.videoId}`}
        frameBorder="0"
      />
    </div>
  )
}

Player.propTypes = {
  item: PropTypes.object.isRequired
}

export default Player
