import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/SectionTitle.module.css'


function SectionTitle({title, customAlignment, customColor}) {
  const alignment = customAlignment ? customAlignment : 'left'
  const color = customColor ? customColor : 'light'
  return <div className={`${style.title} ${style[alignment]} ${style[color]}`}>{title}</div>
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default SectionTitle
