import React from 'react'
import Header from './header/components/Header'
import Section from './section/components/Section'


function ErrorScreen() {
  const title = `Something went wrong`
  const item = {
    Content: `Something went wrong. Please try again later ...`
  }

  return (
    <React.Fragment>
      <Header title={title} />
      <Section item={item} />
    </React.Fragment>

  )
}

export default ErrorScreen
