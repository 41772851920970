import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/HTMLText.module.css'


function HTMLText({text, customAlignment, customColor}) {
  const alignment = customAlignment ? customAlignment : 'left'
  const color = customColor ? customColor : 'light'
  const html = text ? text.replace('/uploads/', `${process.env.REACT_APP_API_URL}/uploads/`) : ''
  return <div className={`${style.container} ${style[alignment]} ${style[color]}`} dangerouslySetInnerHTML={{__html: html}} />
}

HTMLText.propTypes = {
  text: PropTypes.string.isRequired
}


export default HTMLText
