import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/FooterLink.module.css'


function FooterLink({label, title, url}) {
  return (
    <div className={style.container}>
      <span className={style.label}>{label ?? ''}:</span>
      <a href={url ?? ''} className={style.link}>{title ?? ''}</a>
    </div>
  )
}

FooterLink.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default FooterLink
