import React from 'react'
import PropTypes from 'prop-types'
import HolidayHomeContent from './HolidayHomeContent'
import HolidayHomeCalendar from './HolidayHomeCalendar'
import HolidayHomeImages from './HolidayHomeImages'
import style from '../styles/HolidayHome.module.css'


function HolidayHome({item}) {
  const calendar = item.Calendar ? <HolidayHomeCalendar calendar={item.Calendar} date={new Date()} /> : ''
  const images = item.MainImage ? <HolidayHomeImages item={item} /> : ''

  return (
    <div className={style.container}>
      {images}
      <div className={style.content}>
        <HolidayHomeContent item={item} />
        {calendar}
      </div>
    </div>
  )
}

HolidayHome.propTypes = {
  item: PropTypes.object.isRequired
}

export default HolidayHome
