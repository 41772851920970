import React from 'react'
import PropTypes from 'prop-types'
import { getSize } from '../../Helpers'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import { Link } from 'react-router-dom'
import style from '../styles/Gallery.module.css'


function Gallery({items}) {
  const images = items.map(image => {
    return (
      <a href={getSize(image.Image, 'large')} key={image._id} className={style.image}>
        <img src={getSize(image.Image, 'smaill')} alt={image.Description} />
      </a>
    )
  })

  return (
    <div className={style.container}>
      <SimpleReactLightbox>
        <SRLWrapper>
          {images}
        </SRLWrapper>
      </SimpleReactLightbox>
    </div>
  )
}

Gallery.propTypes = {
  items: PropTypes.array.isRequired
}

export default Gallery
