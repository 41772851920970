import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/BoxContent.module.css'


function BoxContent({children}) {
  return <div className={style.container}>{children}</div>
}

BoxContent.propTypes = {
  children: PropTypes.array.isRequired
}

export default BoxContent
