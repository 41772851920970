import React from 'react'
import TestimonialText from './TestimonialText'
import TestimonialAuthor from './TestimonialAuthor'
import PropTypes from 'prop-types'
import style from '../styles/Testimonial.module.css'


function Testimonial({text, author, testimonials, active}) {
  const indicators = testimonials.map((item, i) => {
    const color = i === active ? style.indicatorActive : style.indicator
    return <div className={color} key={i}></div>
  })

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <TestimonialText text={text} />
        <div className={style.indicators}>
          {indicators}
        </div>
        <TestimonialAuthor author={author} />
      </div>
    </div>
  )
}

Testimonial.propTypes = {
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired
}

export default Testimonial