import React from 'react'
import PropTypes from 'prop-types'
import HTMLText from '../../text/components/HTMLText'
import style from '../styles/BoxText.module.css'


function BoxText({text, color}) {
  return (
      <div className={style.container}>
        <HTMLText text={text} customColor={color} />
      </div>
  )

}

BoxText.propTypes = {
  text: PropTypes.string.isRequired
}

export default BoxText
