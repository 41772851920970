import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/Header.module.css'

import Campsite from '../images/campsite.png'
import Home from '../images/home.png'


function Header({title, icon}) {
  const images = {
    'campsite': Campsite,
    'home': Home
  }

  const image = icon ? <div className={style.icon} style={{backgroundImage: `url('${images[icon]}')`}}></div> : ''

  return (
    <div className={style.container}>
      {image}
      <div className={style.title}>{title}</div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired
}

export default Header
