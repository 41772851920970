import React from 'react'
import PropTypes from 'prop-types'
import { getSize } from '../../Helpers'
import SimpleReactLightbox, { SRLWrapper, useLightbox } from 'simple-react-lightbox'
import style from '../styles/HolidayHomeImages.module.css'


function HolidayHomeImages({item}) {
  const { openLightbox } = useLightbox()

  const open = (index) => {
    openLightbox(index)
  }

  //<a href={getSize(item.MainImage, 'large')} className={style.image} onClick={e => open(e, 0)}>
  const main = (
      <div style={{backgroundImage: `url(${getSize(item.MainImage, 'medium')})`}} className={style.image} onClick={() => openLightbox(0)}>
        <img src={getSize(item.MainImage, 'large')} alt={''} />
      </div>
  )

  const images = item.Images.map((image, index) => {
    return (
      <div style={{backgroundImage: `url(${getSize(image, 'medium')})`}} key={image._id} className={style.image} onClick={() => openLightbox(index)}>
        <img src={getSize(image, 'large')} alt={''} />
      </div>
    )
  })

  return (
    <div className={style.container}>
      <div className={style.main}>
        <SimpleReactLightbox>
          <SRLWrapper>
            {main}
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className={style.grid}>
        <SimpleReactLightbox>
          <SRLWrapper>
            {images}
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
    </div>
  )
}

HolidayHomeImages.propTypes = {
  item: PropTypes.object.isRequired
}

export default HolidayHomeImages
