import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/BoxTitle.module.css'


function BoxTitle({title, color}) {
  return <div className={`${style.title} ${style.color}`}>{title}</div>
}

BoxTitle.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
}

export default BoxTitle
