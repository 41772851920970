import React from 'react'
import style from '../styles/BoxImage.module.css'


function BoxImage({image}) {
  return (
    <div className={style.image} style={{backgroundImage: `url('${image}')`}}></div>
  )
}

export default BoxImage
