import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/GridContainer.module.css'


function GridContainer({children}) {
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        {children}
      </div>
    </div>
  )
}

GridContainer.propTypes = {
  children: PropTypes.array.isRequired
}

export default GridContainer
