import React from 'react'
import FooterLogo from './FooterLogo'
import FooterAddress from './FooterAddress'
import FooterContact from './FooterContact'
import FooterSocial from './FooterSocial'
import style from '../styles/Footer.module.css'


function Footer({settings}) {
  return (
    <div className={style.container}>
      <FooterLogo />
      <FooterAddress settings={settings}  />
      <FooterContact settings={settings} />
      <FooterSocial settings={settings} />
    </div>
  )
}

export default Footer
