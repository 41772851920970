import slugify from 'slugify'
import LIVE_URL from './Config'


const getSize = (image, size) => {
  if (!image) return ''

  if (image.formats.hasOwnProperty(size)) {
    return LIVE_URL + image.formats[size].url
  } else {
    return LIVE_URL + image.url
  }
}

const slug = (page) => {
  return page.Template !== 'Home' && page.MetaTitle ? slugify(page.MetaTitle, {
    locale: 'en',
    lower: true
  }) : ''
}


export { getSize, slug }
