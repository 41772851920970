import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/BoxContainer.module.css'


function BoxContainer({children}) {
  return <div className={style.container}>{children}</div>
}

BoxContainer.propTypes = {
  children: PropTypes.array.isRequired
}

export default BoxContainer
