import React, {useState} from 'react'
import PropTypes from 'prop-types'
import style from '../styles/HolidayHomeCalendar.module.css'


function HolidayHomeCalendar({calendar, date}) {
  const [year, setYear] = useState(date.getFullYear())
  const [month, setMonth] = useState(date.getMonth() + 1)

  const next = () => {
    if (month + 1 === 12) {
      setYear(year + 1)
    } else {
      setMonth(month + 1)
    }
  }

  const prev = () => {
    if (!(month === date.getMonth() + 1 && year === date.getFullYear())) {
      if (month - 1 === -1) {
        setYear(year - 1)
      } else {
        setMonth(month - 1)
      }
    }
  }

  const link = `https://ibookingsystem.co.uk/availability/longbeach-holiday-homes/calendar/${calendar}?l=2&y=${year}&m=${month}`

  return (
    <div className={style.container}>
      <iframe title={calendar} src={link} height={250} />
      <div className={style.control}>
        <span onClick={prev}>{`« Prev`}</span>
        <span onClick={next}>{`Next »`}</span>
      </div>
      <div className={style.green}>Available</div>
      <div className={style.red}>Booked / Unavailable</div>
    </div>
  )
}

HolidayHomeCalendar.propTypes = {
  calendar: PropTypes.string.isRequired
}

export default HolidayHomeCalendar
