import React, { useContext } from 'react'
import HeroButton from './HeroButton'
import { slug } from '../../Helpers'
import { Context } from '../../App'
import style from '../styles/HeroButtons.module.css'


function HeroButtons({buttons}) {
  const context = useContext(Context)
  const pages = context.pages

  const items = buttons.filter(b => b.page).map(button => {
    const page = pages.find(p => p._id === button.page)
    
    return <HeroButton title={button.Title} key={button._id} url={slug(page)} color={button.Color} />
  })

  return (
    <div className={style.container}>
      {items}
    </div>
  )
}

export default HeroButtons
