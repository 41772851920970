import React, { useEffect, useState } from 'react'
import Testimonial from './Testimonial'
import PropTypes from 'prop-types'
import { getSize } from '../../Helpers'
import style from '../styles/Testimonials.module.css'


function Testimonials({testimonials, image}) {
  const [active, setActive] = useState(0)
  const item = testimonials[active]

  const move = (value) => {
    if (value >= 0) {
      if (active + value < testimonials.length) {
        setActive(active + value)
      } else {
        setActive(0)
      }
    } else {
      if (active + value < 0) {
        setActive(testimonials.length - 1)
      } else {
        setActive(active + value)
      }
    }
  }

  useEffect(() => {
    let timeout = setTimeout(() => {
      move(1)
    }, 6000)

    return () => {
      clearTimeout(timeout)
    }
  })

  return (
    <div className={style.container}>
      <div className={style.bgContainer} style={{backgroundImage: `url('${getSize(item.Image, 'medium')}')`}} />
      <div className={style.testimonials}>
        <div className={style.backward} onClick={() => { move(-1) }}></div>
        <div className={style.testimonialContainer}>
          <div className={style.quote} />
          <Testimonial text={item.Text} author={item.Author} testimonials={testimonials} active={active} />
        </div>
        <div className={style.forward} onClick={() => { move(1) }}></div>
      </div>
    </div>
  )
}

Testimonials.propTypes = {
  testimonials: PropTypes.array.isRequired
}

export default Testimonials
