import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/FooterSocialLink.module.css'


function FooterSocialLink({url, icon}) {
  const className = `${style.link} ${style[icon]}`

  return (
    <a href={url} className={className} target={`_blank`}></a>
  )
}

FooterSocialLink.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
}

export default FooterSocialLink
