import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/TestimonialAuthor.module.css'


function TestimonialAuthor({author}) {
  return <div className={style.container}>{author}</div>
}

TestimonialAuthor.propTypes = {
  author: PropTypes.string.isRequired
}

export default TestimonialAuthor