import React from 'react'
import PropTypes from 'prop-types'
import FooterText from './FooterText'
import FooterItem from './FooterItem'


function FooterAddress({settings}) {
  const address = [
    settings.Address?.CompanyName,
    settings.Address?.Address1,
    `${settings.Address?.Address2} ${settings.Address?.Postcode}`
  ].map((text, index) => <FooterText key={index} text={text ?? ''} />)

  return (
    <FooterItem title={`Address`}>
      {address}
    </FooterItem>
  )
}

FooterAddress.propTypes = {
  settings: PropTypes.object.isRequired
}

export default FooterAddress
