import React from 'react'
import NavigationSocialLink from './NavigationSocialLink'
import style from '../styles/NavigationSocials.module.css'


function NavigationSocials({settings}) {
  const links = []
  
  if (settings.FacebookURL) {
    links.push({
      url: settings.FacebookURL,
      icon: 'facebook'
    })
  }

  if (settings.TwitterURL) {
    links.push({
      url: settings.TwitterURL,
      icon: 'twitter'
    })
  }

  return (
    <div className={style.container}>
      {links.map((link, index) =>
        <NavigationSocialLink key={index} url={link.url} icon={link.icon} />
      )}
    </div>
  )
}

export default NavigationSocials
