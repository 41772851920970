import React from 'react'
import HeroButtons from './HeroButtons'
import HeroTitle from './HeroTitle'
import PropTypes from 'prop-types'
import { getSize } from '../../Helpers'
import style from '../styles/Hero.module.css'


function Hero({item}) {
  return (
    <div className={style.hero} style={{backgroundImage: `url('${getSize(item.Image, 'large')}')`}}>
      <div className={style.container}>
        <HeroTitle title={item.Text1} size={`small`} />
        <HeroTitle title={item.Text2} size={`large`} />
        <HeroButtons buttons={item.HeroButton} />
      </div>
    </div>
  )
}

Hero.propTypes = {
  item: PropTypes.object.isRequired
}

export default Hero
