import React, { useState } from 'react'
import LIVE_URL from '../../Config'
import style from '../styles/ContactForm.module.css'


function ContactForm() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')

  const disabled = !(name && email && message)
  const button = <button type={'submit'} disabled={disabled}>Submit</button>

  const handleSubmit = (e) => {
    e.preventDefault()
    if (e.target.checkValidity()) {
      fetch(`${LIVE_URL}/emails`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          email: email,
          message: message
        }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setStatus(<div className={style.error}>{data.error}</div>)
        } else {
          setName('')
          setEmail('')
          setMessage('')
          setStatus(<div className={style.success}>{data.success}</div>)
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        setStatus(<div className={style.error}>{`Something went wrong. Please try again later.`}</div>)
      })
    }
  }


  return (
    <div className={style.container}>
      <form onSubmit={e => handleSubmit(e)}>
        {status}
        <input type={`text`} value={name} required={true} placeholder={'Name'} onChange={(e) => setName(e.target.value)} />
        <input type={`email`} value={email} required={true} placeholder={'Email'} onChange={(e) => setEmail(e.target.value)} />
        <textarea value={message} required={true} placeholder={'Message'} onChange={(e) => setMessage(e.target.value)} />
        {button}
      </form>
    </div>
  )
}

export default ContactForm
