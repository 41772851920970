import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/HolidayHomes.module.css'


function HolidayHomes({children}) {
  return (
    <div className={style.container}>
      {children}
    </div>
  )
}


export default HolidayHomes
