import React from 'react'
import PropTypes from 'prop-types'
import SectionTitle from '../../section/components/SectionTitle'
import HTMLText from '../../text/components/HTMLText'
import style from '../styles/HolidayHomeContent.module.css'


const pluralize = (verb, value) => {
  if (value === 1) {
    return `${value} ${verb}`
  } else {
    return `${value} ${verb}s`
  }
}


function HolidayHomeContent({item}) {
  const images = []
  const guests = item.Guests ? <span className={`${style.icon} ${style.people}`}>{pluralize('Guest', item.Guests)}</span> : ''
  const bedrooms = item.Bedrooms ? <span className={`${style.icon} ${style.bed}`}>{pluralize('Bedroom', item.Bedrooms)}</span> : ''
  const bathrooms = item.Bathrooms ? <span className={`${style.icon} ${style.bath}`}>{pluralize('Bathroom', item.Bathrooms)}</span> : ''

  return (
    <div className={style.container}>
      <SectionTitle title={item.Title} />
      <div className={style.icons}>
        {guests}
        {bedrooms}
        {bathrooms}
      </div>
      <HTMLText text={item.Content ? item.Content : ''} />
    </div>
  )
}

HolidayHomeContent.propTypes = {
  item: PropTypes.object.isRequired
}

export default HolidayHomeContent
