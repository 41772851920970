import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/FooterText.module.css'


function FooterText({text}) {
  return <div className={style.text}>{text ?? ''}</div>
}

FooterText.propTypes = {
  text: PropTypes.string.isRequired
}

export default FooterText
