import React from 'react'
import PropTypes from 'prop-types'
import { getSize } from '../../Helpers'
import style from '../styles/GridBox.module.css'


function GridBox({title, image}) {
  const thumbnail = getSize(image, 'thumbail')
  return (
    <div className={style.container}  style={{backgroundImage: `url('${thumbnail}')`}}>
      {title}
    </div>
  )
}

GridBox.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired
}

export default GridBox
