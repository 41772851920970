import React, { useContext } from 'react'
import NavigationLink from './NavigationLink'
import slugify from 'slugify'
import { Context } from '../../App'
import { slug } from '../../Helpers'


function NavigationLinks({navigation, setIsActive}) {
  const context = useContext(Context)
  const pages = context.pages

  return navigation ? navigation.filter(link => link.Page).map(link => {
    const page = pages.find(p => p._id === link.Page)

    return <NavigationLink setIsActive={setIsActive} title={link.Title} key={link._id} url={`/${slug(page)}`} />
  }) : ''
}

export default NavigationLinks
