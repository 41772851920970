import React from 'react'
import LIVE_URL from '../../Config'
import style from '../styles/IBooking.module.css'


function IBooking({name}) {
  return <iframe title={'IBooking Page'} className={style.container} src={`${LIVE_URL}/ibooking/${name}.html`} />
}

export default IBooking
