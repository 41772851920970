import React from 'react'
import { Link } from 'react-router-dom'
import style from '../styles/FooterLogo.module.css'


function FooterLogo() {
  return <Link to={`/`} className={style.logo}></Link>
}

export default FooterLogo
