import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import style from '../styles/Button.module.css'


function Button({label, url, color, center}) {
  const alignment = center ? 'center' : 'left'
  return (
    <Link to={url} className={`${style.button} ${style[color]} ${style[alignment]}`}>
      {label}
    </Link>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
}

export default Button
