import React from 'react'
import Hero from './hero/components/Hero'
import Section from './section/components/Section'
import Header from './header/components/Header'
import BoxContainer from './box/components/BoxContainer'
import Box from './box/components/Box'
import GridContainer from './grid/components/GridContainer'
import GridBox from './grid/components/GridBox'
import Blog from './blog/components/Blog'
import Testimonials from './testimonial/components/Testimonials'
import Gallery from './gallery/components/Gallery'
import Contact from './contact/components/Contact'
import IBooking from './ibooking/components/IBooking'
import HolidayHomes from './homes/components/HolidayHomes'
import HolidayHome from './homes/components/HolidayHome'
import Player from './player/components/Player'


const getGridItems = (items) => {
  return items.map(item => <GridBox image={item.Image} title={item.Title} key={item._id} />)
}

const getGrid = (item) => <GridContainer>{getGridItems(item.Grid)}</GridContainer>

const getText = (item) => <Section item={item} key={item._id} />

const getTestimonials = (item) => item.Testimonial.length > 0 ? <Testimonials testimonials={item.Testimonial} /> : ''

const getBar = (item) => <Header title={item.Title} icon={item.Icon} />

const getHero = (item) => <Hero item={item} />

const getBoxItems = (items) => {
  return items.map(item => {
    return <Box item={item} key={item._id} />
  })
}

const getBoxes = (item) => <BoxContainer>{getBoxItems(item.Box)}</BoxContainer>

const getGallery = (item) => <Gallery items={item.Image} />

const getPlayer = (item) => <Player item={item} />

const getHomeItem = (items) => {
  return items.map(item => <HolidayHome key={item._id} item={item} />)
}

const getHomes = (item) => <HolidayHomes>{getHomeItem(item.Home)}</HolidayHomes>

const getContact = (item) => <Contact item={item} />

const getBlogItems = (items) => {
  return items.filter(item => item.page !== null).map(item => {
    const record = {
      Content: item.Excerpt,
      Title: item.page.MetaTitle,
      date: item.page.published_at,
      TextAlignment: 'left',
      Color: 'light',
      Button: {
        Label: 'Read More',
        page: item.page
      }
    }
    return <Section item={record} key={item._id} />
  })
}

const getBlog = (item) => <Blog>{getBlogItems(item.News)}</Blog>

const getIbooking = (item) => <IBooking name={item.Name} />

const getByComponent = (c) => {
  const components = {
    'page.text': getText,
    'page.grid': getGrid,
    'page.contact': getContact,
    'page.bar': getBar,
    'page.hero': getHero,
    'page.gallery': getGallery,
    'page.testimonials': getTestimonials,
    'page.boxes': getBoxes,
    'page.blog': getBlog,
    'page.player': getPlayer,
    'page.ibooking-frame': getIbooking,
    'page.homes': getHomes
  }
  return components[c.__component](c)
}

function PageScreen({page}) {
  const content = page.Components.map((c, index) => {
    return (
      <React.Fragment key={index}>
        {getByComponent(c)}
      </React.Fragment>
    )
  })

  return (
    <React.Fragment>
      {content}
    </React.Fragment>
  )
}

export default PageScreen
