import React, { useState, useEffect, createContext } from 'react'
import GoogleFontLoader from 'react-google-font-loader'
import Navigation from './navigation/components/Navigation'
import Meta from './metatags/components/Meta'
import Footer from './footer/components/Footer'
import LIVE_URL from './Config'
import NotFoundScreen from './NotFoundScreen'
import PageScreen from './PageScreen'
import LoadingScreen from './LoadingScreen'
import ErrorScreen from './ErrorScreen'
import { slug } from './Helpers'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import style from './styles/App.module.css'


export const Context = createContext()


function App() {
  const [pages, setPages] = useState([])
  const [settings, setSettings] = useState({})
  const [navigation, setNavigation] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const requests = [
      ['pages', setPages],
      ['settings', setSettings],
      ['navigation', setNavigation]
    ]

    const promises = requests.map(item => fetch(`${LIVE_URL}/${item[0]}`))

    Promise.all(promises)
    .then(responses => Promise.all(responses.map(r => r.json())))
    .then(result => {
      result.forEach((data, i) => {
        requests[i][1](data)
      })
      setIsLoading(false)
    })
    .catch(error => {
      console.log('error', error)
      setIsError(true)
    })
  }, [])

  const fonts = [{
      font: 'Roboto',
      weights: [400, 700],
    }, {
      font: 'Roboto Slab',
      weights: [400, 700],
    }
  ]

  if (isError) return <ErrorScreen />
  if (isLoading) return <LoadingScreen />

  const routes = pages.map(page => {
    const dispatcher = [
      {
        template: 'Home',
        controller: <PageScreen page={page} />
      }, {
        template: 'Page',
        controller: <PageScreen page={page} />
      }
    ]

    const item = dispatcher.find(item => item.template === page.Template)

    return (
      <Route path={`/${slug(page)}`} key={page._id} exact>
          <Meta title={page.MetaTitle} description={page.MetaDescription} settings={settings} />
          {item.controller}
      </Route>
    )
  })

  return (
      <Router>
        <Context.Provider value={{ pages: pages }}>
        <GoogleFontLoader fonts={fonts} />
        <div className={style.newSite}>New website coming in 2024</div>
        <div className={style.container}>
            
            <Navigation navigation={navigation} settings={settings} />
            <div className={style.body}>
              <div className={style.stretch}>
                <Switch>
                  {routes}
                  <Route path='*' status={404}>
                    <NotFoundScreen />
                  </Route>
                </Switch>
              </div>
              <Footer settings={settings} />
            </div>
          </div>
          </Context.Provider>
      </Router>
  )
}

export default App
