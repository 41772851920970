import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import BoxContent from './BoxContent'
import BoxImage from './BoxImage'
import BoxIcon from './BoxIcon'
import BoxTitle from './BoxTitle'
import BoxText from './BoxText'
import Button from '../../button/components/Button'
import { Context } from '../../App'
import { getSize, slug } from '../../Helpers'
import style from '../styles/Box.module.css'

import home from '../images/home.png'
import camp from '../images/camp.png'

function Box({item}) {
  const context = useContext(Context)
  const pages = context.pages

  const page = pages.find(p => p._id === item.page)

  return (
    <div className={style.box}>
      <BoxImage image={getSize(item.Image, 'medium')} />
      <BoxContent>
        <BoxIcon icon={item.Color === 'light' ? home : camp} />
        <BoxTitle title={item.Title} color={item.Color} />
        <BoxText text={item.Text} color={item.Color} />
        <div className={style.button}>
          <Button label={item.Label} color={item.Color} url={slug(page)} center={true} />
        </div>
      </BoxContent>
    </div>
  )
}

Box.propTypes = {
  item: PropTypes.object.isRequired
}

export default Box
