import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import SectionContent from './SectionContent'
import SectionImage from './SectionImage'
import Button from '../../button/components/Button'
import SimpleReactLightbox, { SRLWrapper, useLightbox } from 'simple-react-lightbox'
import { slug } from '../../Helpers'
import { Context } from '../../App'
import style from '../styles/Section.module.css'


function Section({item}) {
  const context = useContext(Context)
  const pages = context.pages

  const { openLightbox } = useLightbox()
  const headerImage = item.HeaderImage ? <SectionImage image={item.HeaderImage} /> : ''

  const sideImage = item.SideImage ? (
    <SimpleReactLightbox>
      <SRLWrapper>
        <SectionImage image={item.SideImage} onClick={() => openLightbox(0)} />
      </SRLWrapper>
    </SimpleReactLightbox>
  ) : ''

  const firstItem = item.FirstItem ? item.FirstItem : 'content'
  const background = item.BackgroundColor ? item.BackgroundColor : 'gray'
  const center = item.TextAlignment !== 'left' ? true : false
  const button = item.Button ? <Button label={item.Button.Label} url={slug(pages.find(p => p._id === item.Button.page))} color={item.Color} center={center} /> : ''

  const section = (
    <div className={style.section}>
      {headerImage}
      <SectionContent item={item} button={button} />
    </div>
  )


  const contentFirst = (
    <React.Fragment>
      {section}
      {sideImage}
    </React.Fragment>
  )

  const imageFirst = (
    <React.Fragment>
      {sideImage}
      {section}
    </React.Fragment>
  )

  const html = firstItem === 'content' ? contentFirst : imageFirst

  return (
    <div className={`${style.container} ${style[background]}`}>
      {html}
    </div>
  )
}

Section.propTypes = {
  item: PropTypes.object.isRequired
}

export default Section
