import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import style from '../styles/NavigationLink.module.css'


function NavigationLink({title, url, setIsActive}) {
  return (
    <Link to={url} onClick={() => setIsActive(false)} className={style.link}>
      <div className={style.title}>{title}</div>
    </Link>
  )
}

NavigationLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default NavigationLink
