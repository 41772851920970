import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/NavigationContactLink.module.css'


function NavigationContactLink({title, url, icon}) {
  return (
    <a href={url} className={`${style.link} ${style[icon]}`}>
      <div className={style.title}>{title}</div>
    </a>
  )
}

NavigationContactLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
}

export default NavigationContactLink
