import React from 'react'
import PropTypes from 'prop-types'
import style from '../styles/HeroTitle.module.css'


function HeroTitle({title, size}) {
  return (
    <div className={`${style.title} ${style[size]}`}>
      {title}
    </div>
  )
}

HeroTitle.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired
}

export default HeroTitle
