import React from 'react'
import MetaTags from 'react-meta-tags'


function Meta({title, description, settings}) {
  return (
    <MetaTags>
      <title>{`${title} | ${settings.WebsiteMetaTitle}`}</title>
      <meta name={'description'} content={description} />
      <meta property={'og:title'} content={title} />
      <meta name="google-site-verification" content="WZ_ISseauvWJULKYCJMNz9lPEDUiu9_qcYvICSB-KGQ" />
    </MetaTags>
  )
}

export default Meta