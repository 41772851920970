import React from 'react'
import FooterItem from './FooterItem'
import FooterSocialLink from './FooterSocialLink'


function FooterSocial({settings}) {
  const links = []
  
  if (settings.FacebookURL) {
    links.push({
      url: settings.FacebookURL,
      icon: 'facebook'
    })
  }

  if (settings.TwitterURL) {
    links.push({
      url: settings.TwitterURL,
      icon: 'twitter'
    })
  }

  return (
    <FooterItem title={`Social`}>
      {links.map((link, index) =>
        <FooterSocialLink key={index} url={link.url} icon={link.icon} />
      )}
    </FooterItem>
  )
}

export default FooterSocial
