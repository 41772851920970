import React from 'react'
import NavigationContactLink from './NavigationContactLink'
import style from '../styles/NavigationContacts.module.css'


function NavigationContacts({settings}) {
  const email = settings.Email ?? ''
  const telephone = settings.Telephone ?? ''

  return (
    <div className={style.container}>
      <NavigationContactLink title={telephone} url={`tel:${telephone}`} icon={`telephone`} />
      <NavigationContactLink title={email} url={`mailto:${email}`} icon={`email`} />
    </div>
  )
}

export default NavigationContacts
